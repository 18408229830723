<template>
    <v-row justify="space-around">
      <v-col cols="12">
        <v-sheet class="py-2 px-1" rounded>
          <v-row class="align-center pl-4">
            <v-btn
              color="color3 color3Text--text"
              class="mr-2"
              small fab
              @click.stop="() => { if (!noDialog) openDialog() }"
              :loading="iLoading"
            >
              <v-icon>fas fa-tags</v-icon>
            </v-btn>
            <div>
              <v-chip-group
                v-if="tags.length > 0"
              >
                <v-scroll-x-reverse-transition group>
                  <v-chip
                    v-for="tag in tags"
                    :key="tag.tag"
                    class="pr-1"
                    @click.stop="selected = tag"
                    :color="selected === tag ? 'success' : ''"
                  >
                    {{ tag.tag }}
                    <v-avatar
                    :color="selected === tag ? 'success darken-2' : 'grey'"
                      class="ml-2"
                    >
                      {{tag.count}}
                    </v-avatar>
                  </v-chip>
                </v-scroll-x-reverse-transition>
              </v-chip-group>
              <span v-else>You have never tagged {{player.fullname}}</span>
            </div>
          </v-row>
          <v-row v-if="noDialog">
            <v-col cols="12">
              <v-card v-if="selected">
                <v-toolbar color="color1 color1Text--text">
                  <v-toolbar-title>Tag History</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn color="color2 color2Text--text" small fab @click.stop="dialog = false">
                    <v-icon>fas fa-times</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-title class="title">
                  {{selected.tag}}
                </v-card-title>
                <v-card-text>
                  <v-data-table
                    :headers="[
                      { text: 'Tagged On', value: 'dtCreated'},
                      { text: '', value: 'actions'}
                    ]"
                    :items="selected.all"
                    :hide-default-footer="selected.count < 11"
                    item-key="id"
                    :loading="loading"
                    dense
                  >
                    <template v-slot:[`item.dtCreated`]={item}>
                      {{item.dtCreated | readableDate}} {{item.dtCreated | time}}
                    </template>
                    <template v-slot:[`item.actions`]={item}>
                      <v-btn
                        color="error"
                        text icon small
                        @click.stop="() => { confirm = item }"
                      >
                        <v-icon>fas fa-trash</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-card-text>
                <v-expand-transition>
                  <v-container
                    v-if="confirm"
                    class="d-flex transition-fast-in-fast-out title v-card--reveal"
                    style="height: 100%;"
                  >
                    <v-card style="max-width: 300px">
                      <v-card-title class="pb-0">Are you sure?</v-card-title>
                      <v-card-text class="pt-0">Delete this tag from<br>{{confirm.dtCreated | unixToLocalDt}}?</v-card-text>
                      <v-card-actions class="d-flex justify-center">
                        <v-btn color="success" :loading="loading" @click.stop="deleteTag">Yes</v-btn>
                        <v-btn color="error" @click.stop="confirm=false" :disabled="loading">No</v-btn>
                      </v-card-actions>

                    </v-card>
                  </v-container>
                </v-expand-transition>
              </v-card>
              <v-card v-else>
                <v-toolbar color="color1 color1Text--text">
                  <v-toolbar-title>Tag {{player.firstName}}</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                  <v-combobox
                    ref="cb"
                    v-model="model"
                    :items="availableTags"
                    :search-input.sync="search"
                    hide-selected
                    label="Enter or choose a tag"
                    persistent-hint
                    chips
                    small-chips deletable-chips
                    :loading="loading"
                    clearable
                    multiple
                    :menu-props="{closeOnClick: true}"
                    @change="onChange"
                  >
                  </v-combobox>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="justify-end">
                  <v-fab-transition>
                    <v-btn
                      color="success white--text"
                      small fab
                      :key="model.length"
                      :disabled="!model.length"
                      :loading="loading"
                      @click.stop="postTags"
                    >
                      <v-icon>fas fa-save</v-icon>
                    </v-btn>
                  </v-fab-transition>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
      <v-dialog
        v-if="!noDialog"
        v-model="dialog"
        scrollable
        max-width="500px"
        transition="dialog-transition"
      >
        <v-card v-if="selected">
          <v-toolbar color="color1 color1Text--text">
            <v-toolbar-title>Tag History</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="color2 color2Text--text" small fab @click.stop="dialog = false">
              <v-icon>fas fa-times</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-title class="title">
            {{selected.tag}}
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="[
                { text: 'Tagged On', value: 'dtCreated'},
                { text: '', value: 'actions'}
              ]"
              :items="selected.all"
              :hide-default-footer="selected.count < 11"
              item-key="id"
              :loading="loading"
              dense
            >
              <template v-slot:[`item.dtCreated`]={item}>
                {{item.dtCreated | readableDate}} {{item.dtCreated | time}}
              </template>
              <template v-slot:[`item.actions`]={item}>
                <v-btn
                  color="error"
                  text icon small
                  @click.stop="() => { confirm = item }"
                >
                  <v-icon>fas fa-trash</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
          <v-expand-transition>
            <v-container
              v-if="confirm"
              class="d-flex transition-fast-in-fast-out title v-card--reveal"
              style="height: 100%;"
            >
              <v-card style="max-width: 300px">
                <v-card-title class="pb-0">Are you sure?</v-card-title>
                <v-card-text class="pt-0">Delete this tag from<br>{{confirm.dtCreated | unixToLocalDt}}?</v-card-text>
                <v-card-actions class="d-flex justify-center">
                  <v-btn color="success" :loading="loading" @click.stop="deleteTag">Yes</v-btn>
                  <v-btn color="error" @click.stop="confirm=false" :disabled="loading">No</v-btn>
                </v-card-actions>

              </v-card>
            </v-container>
          </v-expand-transition>
        </v-card>
        <v-card v-else>
          <v-toolbar color="color1 color1Text--text">
            <v-toolbar-title>Tag {{player.firstName}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="color2 color2Text--text" small fab @click.stop="dialog = false">
              <v-icon>fas fa-times</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-combobox
              ref="cb"
              v-model="model"
              :items="availableTags"
              :search-input.sync="search"
              hide-selected
              label="Enter or choose a tag"
              persistent-hint
              chips
              small-chips deletable-chips
              :loading="loading"
              clearable
              multiple
              :menu-props="{closeOnClick: true}"
              @change="onChange"
            >
            </v-combobox>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-fab-transition>
              <v-btn
                color="success white--text"
                small fab
                :key="model.length"
                :disabled="!model.length"
                :loading="loading"
                @click.stop="postTags"
              >
                <v-icon>fas fa-save</v-icon>
              </v-btn>
            </v-fab-transition>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
</template>

<script>
import uniq from 'lodash.uniq'
import moment from 'moment'
import { firstBy } from 'thenby'
import { mapGetters } from 'vuex'

export default {
  props: ['player', 'noDialog'],
  data () {
    return {
      dialog: false,
      selected: null,
      tags: [],
      tagList: [],
      loading: false,
      iLoading: true,
      search: null,
      model: [],
      posting: false,
      confirm: false
    }
  },
  computed: {
    ...mapGetters(['tournament']),
    availableTags () {
      const x = [...this.tagList]
      x.sort()
      this.search && x.unshift(this.search)
      return x
    },
    dto () {
      return this.model.map(m => {
        return {
          playerProfileId: this.player.ppId,
          tag: m,
          tournamentId: this.tournament ? this.tournament.id : null
        }
      })
    }
  },
  methods: {
    onChange () {
      this.$refs.cb.blur()
    },
    openDialog () {
      this.loadTagList()
      this.dialog = true
    },
    loadTags () {
      this.iLoading = true
      this.$VBL.user.tags.get(this.player.ppId)
        .then(r => {
          this.groupTags(r.data)
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.iLoading = false })
    },
    loadTagList () {
      this.loading = true
      this.$VBL.user.tags.get(0, true)
        .then(r => {
          this.tagList = r.data
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    postTags () {
      this.loading = true
      this.$VBL.user.tags.post(this.dto)
        .then(r => {
          this.groupTags(r.data)
          this.model = []
          this.dialog = false
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    groupTags (data) {
      if (data.length) {
        const t = uniq(data.map(m => m.tag))
        this.tags = t.map(tag => {
          const all = data.filter(f => f.tag === tag)
          const last = moment.max(data.map(m => moment(m.dtCreated)))
          return {
            tag: tag,
            all: all,
            count: all.length,
            last: last,
            unix: last.unix()
          }
        }).sort(firstBy('unix', -1))
      } else {
        this.tags = []
      }
    },
    deleteTag () {
      if (this.confirm) {
        const dto = JSON.parse(JSON.stringify(this.confirm))
        dto.deleted = true
        this.loading = true
        this.$VBL.user.tags.post([dto])
          .then(r => {
            this.groupTags(r.data)
            this.confirm = false
            this.selected = this.tags.find(f => f.tag === dto.tag)
          })
          .catch(e => console.log(e.response))
          .finally(() => { this.loading = false })
      }
    }
  },
  watch: {
    dialog: function (v) {
      if (!v) {
        this.model = []
        this.selected = null
      }
    },
    selected: function (v) {
      this.dialog = !!v
    }
  },
  mounted () {
    this.loadTags()
    this.noDialog && this.loadTagList()
  }
}
</script>
